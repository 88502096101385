import noriobake from './g3075.png';
import logo_no from './logo_no.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo_no} />
        <h1>開運のりおばけおみくじ</h1>
        <img src={noriobake} className="App-logo" alt="logo" />
        <p>★あけおめ2023★<br />のりおばけLINEスタンプ販売中！<br />
          <a href="https://store.line.me/stickershop/author/3578893/ja" class="line">
            スタンプはこちらから</a>
        </p>
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
